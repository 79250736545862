export default (questions, action) => {
  console.log('action', action)
  const { id, question, rating } = action.payload
  switch (action.type) {
    case 'SUBMIT_ANSWER':
      return {
        ...questions,
        [id]: rating
      }
    default:
      return questions
  }
}
