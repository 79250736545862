import React, { useReducer } from 'react'
import Context from 'components/common/Context'
import questionsReducer from './questionsReducer'
import userReducer from './userReducer'
import preferenceReducer from './preferenceReducer'

export default ({ children }) => {
  const [questions, dispatchQuestions] = useReducer(questionsReducer, [])
  const [preferences, dispatchPreferences] = useReducer(preferenceReducer,
    {
      colour: "Clear",
      griptexture: "Default",
      innergrip: "Default",
      material: "Default",
      outergrip: "Default",
      type: "Standard"
    })
  const [user, dispatchUserAction] = useReducer(userReducer, {})

  return (
    <Context.Provider
      value={{
        questions,
        dispatchQuestions,
        user,
        dispatchUserAction,
        preferences,
        dispatchPreferences
      }}
    >
      {children}
    </Context.Provider>
  )
}
