export default (preferences, action) => {
  console.log('action', action)
  const { id, question, answer } = action.payload
  switch (action.type) {
    case 'SUBMIT_ANSWER':
      return {
        ...preferences,
        [id]: answer
      }
    default:
      return preferences
  }
}
