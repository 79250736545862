export default (user, action) => {
  console.log('action', action.payload)
  switch (action.type) {
    case 'VERIFY_USER':
      return {
        ...user,
        isLoggedIn: true,
        userid: action.payload.id,
        first_name: action.payload.first_name
      }
    default:
      return user
  }
}
